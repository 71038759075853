import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { graphql } from "gatsby";
import { RCL, RCL as useTranslation } from "../../components/RCL";
import { useAlerts } from "../../context";
import { BaseLayout } from "../../components/Layouts/BaseLayout";
import { Section } from "../../components/Section";
import { Button } from "../../components/Button";
import { Icon } from "../../components/Icons/Icons";
import { Accordion } from "../../components/Accordion";
import { Alert } from "../../components/Alert";
import { RichTextConnector } from "../../connectors/RichTextConnector";
import * as styles from "./travel-alerts.module.scss";

const TravelAlerts = ({ data, pageContext, location }) => {
	/* Retrieve Page Data from the page query */
	const altLangPages = data.allSitePage;
	const { homepagePaths, defaultHeader, travelAlertsUrl, defaultFooter, activeAlerts, pastAlerts } =
		data;

	const [allActiveAlerts, setAllActiveAlerts] = useState([]);
	const [allPastAlerts, setAllPastAlerts] = useState([]);

	const {
		alerts,
		alertsCount,
		refreshAlerts,
		latestTimestamp,
		localizeTimestamp,
		hasMadeApiCall,
		hasLiveData,
	} = useAlerts();

	useEffect(() => {
		refreshAlerts();
	}, []);

	useEffect(() => {
		if (hasMadeApiCall) {
			if (hasLiveData) {
				setAllActiveAlerts(alerts.activeAlerts);
				setAllPastAlerts(alerts.expiredAlerts);
			} else {
				setAllActiveAlerts(activeAlerts.nodes);
				setAllPastAlerts(pastAlerts.nodes);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alerts, activeAlerts, pastAlerts, hasMadeApiCall, hasLiveData]);

	const handleRefreshAlerts = () => {
		refreshAlerts();
	};

	return (
		<BaseLayout
			location={location}
			pageContext={pageContext}
			altLangPages={altLangPages}
			headerNavigation={defaultHeader}
			footerData={defaultFooter}
			homepagePaths={homepagePaths}
			alerts={alertsCount}
			travelAlertsUrl={travelAlertsUrl?.path}
		>
			<Section
				title={useTranslation({ searchKey: "travel-alert" })}
				titleAs="h1"
				titleSize="h1"
				titleAlignment="left"
				align="left"
			>
				<p className={styles.travelAlertsSubheading}>
					<RCL searchKey="travel-advisory-message" />
				</p>
				<Button
					type="button"
					outline={true}
					onClick={handleRefreshAlerts}
					className={styles.travelAlertsLastCheckedBtn}
				>
					<div className={styles.travelAlertsLastCheckedContainer}>
						<p className={styles.travelAlertsLastCheckedText}>
							<span>
								{!hasMadeApiCall ? <RCL searchKey="updating" /> : <RCL searchKey="last-updated" />}
							</span>
							<span>{!hasLiveData ? latestTimestamp : localizeTimestamp(pageContext.locale)}</span>
						</p>
						<Icon
							name="refresh--o"
							className={cx(
								styles.travelAlertsRefreshIcon,
								!hasMadeApiCall && styles.travelAlertsRefreshIconLoading
							)}
						/>
					</div>
				</Button>
			</Section>
			<Section padding="none">
				{!!allActiveAlerts?.length &&
					allActiveAlerts.map(alert => (
						<Alert
							key={alert.sys?.id || alert.contentful_id}
							className={styles.travelAlertsAlerts}
							variant={alert.variant}
							heading={alert.displayTitle}
							headingAs="h2"
							dismissible={false}
							icon={alert.icon}
						>
							<RichTextConnector data={alert?.content} />
						</Alert>
					))}
			</Section>
			{!!allPastAlerts?.length && (
				<Section padding="top">
					<div>
						<Accordion
							variant="tide"
							tabData={allPastAlerts.map(alert => ({
								key: alert.sys?.id || alert.contentful_id,
								toggle: { label: { heading: alert.displayTitle } },
								panel: <RichTextConnector data={alert?.content} />,
							}))}
						/>
					</div>
				</Section>
			)}
		</BaseLayout>
	);
};

/* Declare page prop */
TravelAlerts.propTypes = {
	data: PropTypes.object.isRequired,
	pageContext: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default TravelAlerts;

/* Query page data */
export const pageQuery = graphql`
	query GetTravelAlertsData($date: Date!, $locale: String!) {
		homepagePaths: allSitePage(filter: { context: { template: { eq: "homepage" } } }) {
			edges {
				...homepagePaths
			}
		}
		allSitePage(filter: { context: { template: { eq: "alerts" } } }) {
			edges {
				...LanguageToggle
			}
		}
		defaultHeader: contentfulHeaderNavigation(
			contentful_id: { eq: "4WLelYKXDOXBf7CGGtf52z" }
			node_locale: { eq: $locale }
		) {
			...headerNavigation
		}
		defaultFooter: contentfulFooter(
			contentful_id: { eq: "3TjYjWvnKS88bdse66t4oo" }
			node_locale: { eq: $locale }
		) {
			...Footer
		}
		pastAlerts: allContentfulAlerts(
			filter: { node_locale: { eq: $locale }, expiryDate: { lt: $date } }
			sort: { fields: activeDate, order: DESC }
		) {
			nodes {
				id
				contentful_id
				displayTitle
				content {
					raw
					references {
						__typename
						...LinkWithModal
						...MultiImageBlock
						...ComponentBlock
					}
				}
			}
		}
		activeAlerts: allContentfulAlerts(
			filter: { node_locale: { eq: $locale }, expiryDate: { gt: $date } }
			sort: { fields: [priority, activeDate], order: [ASC, DESC] }
		) {
			nodes {
				id
				contentful_id
				displayTitle
				variant
				icon
				content {
					raw
					references {
						__typename
						...LinkWithModal
						...MultiImageBlock
						...ComponentBlock
					}
				}
			}
		}
		travelAlertsUrl: sitePage(context: { template: { eq: "alerts" }, locale: { eq: $locale } }) {
			path
		}
	}
`;
